import React from 'react'
import classes from './AppHeader.module.css'
import ImpactorLogo from '../../../../assets/img/impactor-logo.png'
import { Box, Burger, Flex, Group, Text, Avatar, Menu } from '@mantine/core'
import { useSelector, useDispatch } from 'react-redux'
import { getImageFile } from '../../../../services/file'
import { IconDoorExit } from '@tabler/icons-react'
import Cookies from 'universal-cookie'
import { useNavigate } from 'react-router-dom'
import { update } from '../../../../store/authData'
import { dataUser } from '../../../../store/authUser'

const AppHeader = ({ mobileToggle, desktopToggle }) => {
  const cookies = new Cookies()
  const navigate = useNavigate()
  const dispatch = useDispatch()
  const { isLoading, detailUser } = useSelector(state => state.user)
  

  const logoutUser = () => {
    cookies.remove('impactor-session')
    dispatch(update({ 'authorization': null, 'isLogin': false, 'isLoading': false }))
    dispatch(dataUser({ 'detailUser': null, 'isLogin': false }))
    navigate('/login')
  }

  const mappingUserLogin = (data) => {
    return (
      <Menu position='bottom-end' offset={3}>
        <Menu.Target>
          <Box style={{ cursor: 'pointer' }}>
            <Flex align="center">
              <Avatar variant='light' src={data.image !== null ? getImageFile(data.image) : null} radius="xl" size="md" color="green.6" />
            </Flex>
          </Box>
        </Menu.Target>
        <Menu.Dropdown style={{ border: '0px', padding: '0px', borderRadius: '10px', boxShadow: 'rgba(0, 0, 0, 0.24) 0px 3px 8px' }}>
          <Box className={classes.bgHeaderDropdown} />
          <Box style={{ width: '16rem', padding: '6px' }}>
            <Box className={classes.menuMember}>
              <Avatar variant='white' src={data.image !== null ? getImageFile(data.image) : null} radius="xl" size="lg" mr={12} />
              <Box mt={8}>
                <Text c='green.6' fw='600' fz={12}>{data.fullName}</Text>
                <Text c='green.6' fz={12}>{data.role.name}</Text>
              </Box>
            </Box>
            <Box mt={80}>
              <Menu.Divider />
              <Menu.Item color='red' leftSection={<IconDoorExit size={14}/>} onClick={logoutUser}>Keluar</Menu.Item>
            </Box>
          </Box>
        </Menu.Dropdown>
      </Menu>
    )
  }
  return (
    <header className={classes.root}>
      <Box className={classes.sectionHeader}>
        <Group gap='xs' align='center'>
          <Burger onClick={mobileToggle} hiddenFrom='sm' color="green.6" size='sm' />
          <Burger onClick={desktopToggle} visibleFrom='sm'color="green.6" size={20} />
          <img src={ImpactorLogo} width='50' alt='pet-logo' />
        </Group>
        <Box>
          {isLoading ? '' : mappingUserLogin(detailUser)}
        </Box>
      </Box>
    </header>
  )
}

export default AppHeader