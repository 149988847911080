import React, { useState, useEffect } from 'react'
import AuthLayout from '../../../layout/AuthLayout'
import { Box, Text, Flex, Pagination, Modal, Button, TextInput, Select } from '@mantine/core'
import { getListCompany, deleteCompany } from '../../../services/company'
import { modalDeleteData } from '../../../components/ui/Modals/modalDeleteData'
import { useSelector } from 'react-redux'
import classes from '../AdminLayout.module.css'
import TableSkeleton from '../../../components/ui/TableSkeleton'
import TableCompany from '../../../components/pages/Company/TableCompany'
import FormCompany from '../../../components/pages/Company/FormCompany'

const defaultParameter = {
  skip: 0,
  take: 10,
  search: '',
  orderBy: 'createdAt',
  order: 'desc',
  isClient: 0
}

const defaultFilter = [
  {
    value: 'createdAt',
    label: 'Terbaru'
  },
  {
    value: 'name',
    label: 'Nama'
  },
]

const labelTable = [
  {
    label: 'No.',
    width: 30
  },
  {
    label: 'Nama Perusahaan',
    width: 'auto'
  },
  {
    label: 'Kode Perusahaan',
    width: 'auto'
  },
  {
    label: 'Sektor Perusahaan',
    width: 'auto'
  },
  {
    label: 'Action',
    width: 100
  },
]


const Company = () => {
  const permissionState = useSelector(state => state.permission.access)
  const [params, setParams] = useState(defaultParameter)
  const [companyList, setCompanyList] = useState([])
  const [count, setCount] = useState(0)
  const [loading, setLoading] = useState(true)
  const [detailData, setDetailData] = useState(null)
  const [openModalForm, setOpenModalForm] = useState(false)

  const mappingOrder = [
    {
      value: 'asc',
      label: params.orderBy === 'createdAt' ? 'Terlama - Terbaru' : 'A - Z'
    },
    {
      value: 'desc',
      label: params.orderBy === 'createdAt' ? 'Terbaru - Terlama' : 'Z - A'
    },
  ]

  const handleGetCompanyList = async () => {
    setLoading(true)
    try {
      const response = await getListCompany(params)
      setCompanyList(response.data)
      setCount(response.count)
    } catch (error) {
      console.log(error)
    } finally {
      setLoading(false)
    }
  }

  useEffect(() => {
    handleGetCompanyList()
    // eslint-disable-next-line
  }, [params])

  const handleChangePage = (val) => {
    setParams((oldVal) => ({ ...oldVal, [oldVal.skip]: (val - 1) * 10 }))
  }

  const handleFilter = (name, val) => {
    setParams((oldVal) => ({ ...oldVal, [name]: val}))
  }

  const actions = {
    edit: (val) => {
      setOpenModalForm(true)
      setDetailData(val)
    },
    delete: (val) => {
      modalDeleteData('perusahaan', val.id, val.name, deleteCompany, handleGetCompanyList)
    },
  }

  const handleAction = (val, type) => {
    return actions[type](val)
  }

  const onCloseModalForm = () => {
    setOpenModalForm(false)
    setDetailData(null)
  }

  const handleCreate = () => {
    const permissionCompany = permissionState['company']
    const createdPermission = permissionCompany.find(val => val.alias === 'company.create')
    if (createdPermission !== undefined) {
      return (
        <Button size='xs' onClick={() => setOpenModalForm(true)}>Tambah Perusahaan</Button>
      )
    }
  }


  return (
    <AuthLayout>
      <Box>
        <Text className={classes.titleLayout} mb={20}>Manajemen Jabatan Pengguna</Text>
        <Box>
          <Flex justify='flex-end' mb={40}>
            {permissionState !== null ? handleCreate() : ''}
          </Flex>
          <Flex direction={{ base: 'column', sm: 'row' }} gap={{ base: 'sm', sm: 'lg' }} justify={{ base: 'center', lg: 'start' }}>
            <TextInput
              placeholder='Cari Jabatan. . .'
              name="search"
              onChange={(val) => handleFilter('search', val.target.value)}
              size='xs'
            />
            <Select
              name="orderBy"
              data={defaultFilter}
              onChange={(e) => [handleFilter('orderBy', e)]}
              value={params.orderBy}
              checkIconPosition="right"
              allowDeselect={false}
              size='xs'
            />
            <Select
              name="order"
              data={mappingOrder}
              onChange={(e) => [handleFilter('order', e)]}
              value={params.order}
              checkIconPosition="right"
              allowDeselect={false}
              size='xs'
            />
          </Flex>
        </Box>
        <Box my={20}>
          {loading ? <TableSkeleton total={5} /> : <TableCompany label={labelTable} data={companyList} actionMethod={handleAction} />}
        </Box>
        <Flex justify='end'>
          <Pagination size='sm' onChange={handleChangePage} total={Math.ceil(count / params.take) || 0} />
        </Flex>
      </Box>
      <Modal opened={openModalForm} onClose={onCloseModalForm} centered closeOnClickOutside={false} size='lg' title={<Text fw='Bold'>{detailData === null ? 'Tambah' : 'Update'} Perusahaan</Text>} withCloseButton={false}>
        <FormCompany dataCompany={detailData} onCloseModal={onCloseModalForm} reloadList={handleGetCompanyList} />
      </Modal>
    </AuthLayout>
  )
}

export default Company