import http from '../plugins/axios'

export const getListRoles = async (params) => {
  try {
    const response = await http.get(`/roles`, { params })
    return response.data
  } catch(error) {
    throw error
  }
}

export const addRole = async (payload) => {
  try {
    const response = await http.post(`/roles`, payload)
    return response.data
  } catch(error) {
    throw error
  }
}

export const updateRole = async (roleId, payload) => {
  try {
    const response = await http.patch(`/roles/${roleId}`, payload)
    return response.data
  } catch(error) {
    throw error
  }
}

export const deleteRole = async (roleId) => {
  try {
    const response = await http.delete(`/roles/${roleId}`)
    return response.data
  } catch(error) {
    throw error
  }
}


export const getDetailPermissionRole = async (roleId) => {
  try {
    const response = await http.get(`/roles/${roleId}/permissions`)
    return response.data
  } catch(error) {
    throw error
  }
}


export const assignRolePermission = async (roleId, payload) => {
  try {
    const response = await http.post(`/roles/${roleId}/permissions`, payload)
    return response.data
  } catch(error) {
    throw error
  }
}