import React from 'react'
import { Table, Badge, Menu, ActionIcon, Tooltip } from '@mantine/core'
import { IconDotsVertical, IconPencil, IconTrash, IconKey, IconInfoCircle } from '@tabler/icons-react'
import { useSelector } from 'react-redux' 

const ActionMenu = ({ handleAction }) => {
  const permissionState = useSelector(state => state.permission.access)

  const permissionUpdate = () => {
    const permissionUser = permissionState['user']
    const updatedPermission = permissionUser.find(val => val.alias === 'user.update')
    if (updatedPermission !== undefined) {
      return (
        <Menu.Item
          leftSection={<IconPencil stroke={1.5} size={14} />}
          onClick={() => handleAction('edit')}
          fw='600'
          fz={11}
        >
          Edit
        </Menu.Item>
      )
    }
  }

  const permissionEditPassword = () => {
    const permissionUser = permissionState['user']
    const updatedPermission = permissionUser.find(val => val.alias === 'user.update')
    if (updatedPermission !== undefined) {
      return (
        <Menu.Item
          leftSection={<IconKey stroke={1.5} size={14} />}
          onClick={() => handleAction('changePassword')}
          fw='600'
          fz={11}
        >
          Ganti Password
        </Menu.Item>
      )
    }
  }

  const permissionDelete = () => {
    const permissionUser = permissionState['user']
    const deletePermission = permissionUser.find(val => val.alias === 'user.delete')
    if (deletePermission !== undefined) {
      return (
        <Menu.Item
          color='red'
          leftSection={<IconTrash stroke={1.5} size={14} />}
          onClick={() => handleAction('delete')}
          fw='600'
          fz={11}
        >
          Hapus
        </Menu.Item>
      )
    }
  }
  return (
    <Menu width={150} position="bottom-end" withArrow arrowPosition="center">
      <Menu.Target>
        <ActionIcon ml={10} mt={6} variant='transparent' size='sm'>
          <IconDotsVertical stroke={1.5} />
        </ActionIcon>
      </Menu.Target>
      <Menu.Dropdown>
        {permissionState !== null ? permissionUpdate() : ''}
        {permissionState !== null ? permissionEditPassword() : ''} 
        {permissionState !== null ? permissionDelete() : ''} 
      </Menu.Dropdown>
    </Menu>
  )
}

const TableUsers = ({ label, data, actionMethod }) => {
  const permissionState = useSelector(state => state.permission.access)


  const mappingTableHead = (data) => data.map((val, index) => {
    return (<Table.Th key={index} width={val.width !== 'auto' ? val.width : ''}>{val.label}</Table.Th>)
  })

  const mappingDataTable = data => data.map((val, index) => {
    const mappingMenu = () => {
      const permissionUser = permissionState['user']
      const updatedPermission = permissionUser.find(val => val.alias === 'user.update')
      const deletedPermission = permissionUser.find(val => val.alias === 'user.delete')
      if (updatedPermission === undefined && deletedPermission === undefined) {
        return (
          <Tooltip label='Anda tidak diizinkan untuk mengakses menu ini'>
            <ActionIcon ml={10} mt={6} variant='transparent' size='xs'>
              <IconInfoCircle stroke={1.5}/>
            </ActionIcon>
          </Tooltip>
        )
      } else {
        return (<ActionMenu handleAction={(type) => actionMethod(val, type)} />)
      }
    }
    return (
      <Table.Tr key={index}>
        <Table.Td>{index + 1}.</Table.Td>
        <Table.Td>{val.fullName}</Table.Td>
        <Table.Td>{val.email}</Table.Td>
        <Table.Td>{val.company ? val.company.name : '-'}</Table.Td>
        <Table.Td>{val.role.name}</Table.Td>
        <Table.Td><Badge size="xs" radius="sm" variant="filled" color={val.isActive ? 'green' : 'red'}>{val.isActive ? 'Active' : 'Non Active'}</Badge></Table.Td>
        <Table.Td>{permissionState !== null ? mappingMenu() : ''}</Table.Td>
      </Table.Tr>
    )
  })

  return (
    <Table.ScrollContainer minWidth={768}>
      <Table highlightOnHover withTableBorder style={{ fontSize: '12px' }}>
        <Table.Thead>
          <Table.Tr>
            {mappingTableHead(label)}
          </Table.Tr>
        </Table.Thead>
        <Table.Tbody>
          {mappingDataTable(data)}
        </Table.Tbody>
      </Table>
    </Table.ScrollContainer>
  )
}

export default TableUsers