import React, { useState } from 'react'
import AuthLayout from '../../../layout/AuthLayout'
import { Box, Text, NavLink, Paper } from '@mantine/core'
import classes from '../AdminLayout.module.css'
import { IconChevronRight } from '@tabler/icons-react'
import Layout from '../../../components/pages/Setting/Layout'
import Theme from '../../../components/pages/Setting/Theme'
import { IconPalette, IconLayout } from '@tabler/icons-react'

const defaultMenu = [
  {
    icon: IconPalette,
    label: 'Tema',
    value: 'theme',
    component: Theme
  },
  {
    icon: IconLayout,
    label: 'Tampilan',
    value: 'layout',
    component: Layout
  }
]

const Setting = () => {
  const [selectedMenu, setSelectedMenu] = useState(null)

  const mappingMenuSetting = (data) => {
    const mappingMenu = data.map((val, index) => {
      return (
        <Paper shadow="sm" radius="md" withBorder mb='md' key={index} onClick={() => setSelectedMenu(val.value)} >
          <NavLink 
            px={12} py={12} label={<Text fz={12} fw={500} >{val.label}</Text>}
            leftSection={<val.icon size="1.2rem" stroke={1.5} />}
            rightSection={<IconChevronRight size="1rem" stroke={1.5} className="mantine-rotate-rtl" />}
            styles={{ borderRadius: '10px' }}
          />
        </Paper>
      )
    })
    return (
      <Box>
        <Text className={classes.titleLayout} mb={20}>Setelan Sistem</Text>
        <Box>
          {mappingMenu}
        </Box>
      </Box>
    )
  }

  const mappingLayoutMenu = (selected) => {
    const selectedTitle = defaultMenu.find(val => val.value === selected).label
    const MenuSelected = defaultMenu.find(val => val.value === selected).component
    return (
      <Box>
        <Text className={classes.titleLayout} mb={20} tt='capitalize'>Setelan {selectedTitle}</Text>
        <Box>
          <MenuSelected onClose={() => setSelectedMenu(null)} />
        </Box>
      </Box>
      
    )
  }
  return (
    <AuthLayout>
      {selectedMenu === null ? mappingMenuSetting(defaultMenu) : mappingLayoutMenu(selectedMenu)}
    </AuthLayout>
  )
}

export default Setting