import React, { useState, useEffect } from 'react'
import { Box, TextInput, Button, Group, Flex } from '@mantine/core'
import { addRole, updateRole } from '../../../services/roles'
import { validation } from '../../../plugins/validation'
import { notificationSuccess, notificationError } from '../../../components/ui/Notifications'

const defaultVal = {
  name: '',
  alias: ''
}

const formValidation = {
  name: {
    isError: false,
    message: ''
  },
  alias: {
    isError: false,
    message: ''
  }
}

const FormRole = ({ dataRole, onCloseModal, reloadList }) => {
  const [formData, setFormData] = useState(defaultVal)
  const [validationForm, setValidationForm] = useState(formValidation)
  const [loadingForm, setLoadingForm] = useState(false)

  const submitRole = async (formRole) => {
    setLoadingForm(true)
    let methodFunction = null
    let titleMessageSuccess = ''
    let captionMessageSuccess = ''
    let titleMessageError = ''
    let captionMessageError = ''
    setValidationForm(formValidation)
    const payload = {
      name: formRole.name,
      alias: formRole.alias
    }
    const isError = validation(payload, setValidationForm)
    if (isError) {
      setLoadingForm(false)
      return
    }
    if (dataRole === null) {
      methodFunction = addRole(payload)
      titleMessageSuccess = 'Tambah Jabatan Berhasil'
      captionMessageSuccess = 'Anda telah berhasil menambahkan jabatan baru'
      titleMessageError = 'Gagal Menambahkan Jabatan'
    } else {
      methodFunction = updateRole(formRole.id, payload)
      titleMessageSuccess = 'Update Jabatan Berhasil'
      captionMessageSuccess = 'Anda telah berhasil mengupdate jabatan'
      titleMessageError = 'Gagal Mengupdate Jabatan'
    }
    try {
      const response = await methodFunction
      if (response) {
        onCloseModal()
        reloadList()
        notificationSuccess(titleMessageSuccess, captionMessageSuccess)
        setLoadingForm(false)
      }
    } catch (error) {
      setLoadingForm(false)
      const errorMessage = error.response.data.message
      captionMessageError = Object.keys(errorMessage) ? errorMessage : 'Silahkan cek kembali form anda'
      notificationError(titleMessageError, captionMessageError)
      Object.values(errorMessage).forEach((el) => {
        Object.keys(formValidation).forEach((element) => {
          if (el.includes(element)) {
            setValidationForm((old) => ({
              ...old,
              [element]: {
                ...old?.[element],
                isError: true,
                message: el
              }
            }))
          }
        })
      })
    }
  }

  useEffect(() => {
    if (dataRole !== null) {
      handleSetForm(dataRole)
    }
  }, [dataRole])

  const handleSetForm = (dataRole) => {
    const dataDetail = {
      id: dataRole.id,
      name: dataRole.name,
      alias: dataRole.alias
    }
    setFormData(dataDetail)
  }

  const handleChangeForm = (name, val) => {
    setFormData((oldVal) => ({ ...oldVal, [name]: val}))
  }


  return (
    <Box>
      <Box mb='md'>
        <TextInput
          name='name'
          value={formData.name}
          label='Nama Jabatan'
          placeholder='Masukkan nama jabatan'
          error={validationForm.name.isError ? `${validationForm.name.message}` : ''}
          onChange={(val) => handleChangeForm('name', val.target.value)}
          withAsterisk
        />
      </Box>
      <Box mb='md'>
        <TextInput
          name='alias'
          value={formData.alias}
          label='Alias Jabatan'
          placeholder='Masukkan nama alias jabatan'
          error={validationForm.alias.isError ? `${validationForm.alias.message}` : ''}
          onChange={(val) => handleChangeForm('alias', val.target.value)}
          withAsterisk
        />
      </Box>
      <Box mt={20}>
        <Flex justify='flex-end'>
          <Group>
            <Button size='xs' variant="outline" onClick={onCloseModal}>Tutup</Button>
            <Button size='xs' loading={loadingForm} variant="filled" onClick={() => submitRole(formData)}>{dataRole === null ? 'Tambah' : 'Update'}</Button>
          </Group>
        </Flex>
      </Box>
    </Box>
  )
}

export default FormRole