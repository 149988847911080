import { createSlice, createAsyncThunk } from "@reduxjs/toolkit"
import Cookies from 'universal-cookie'
import { decodeText, encodedText } from '../plugins/encrypt'
import http from '../plugins/axios'
import { refreshToken } from '../services/authorization'

const cookies = new Cookies()

const generateToken = (session) => {
  let token = ''
  if (session) {
    const decode = decodeText(session)
    token = JSON.parse(decode)
  }
  return token
}

export const getCredential = createAsyncThunk('auth/getCredential', async () => {
  const session = cookies.get('impactor-session')
  let responseCredential = ''
  if (session !== undefined) {
    const sessionLogin = generateToken(session)
    const payload = {
      refresh_token: sessionLogin.refresh_token
    }
    const response = await refreshToken(payload)
    responseCredential = response
  } else {
    responseCredential = 'no token available'
  }
  return responseCredential
})

const authData = createSlice({
  name: 'auth',
  initialState: {
    authorization: null,
    isLogin: false,
    isLoading: true
  },
  reducers: {
    update: (state, action) => {
      state.authorization = action.payload.authorization
      state.isLogin = action.payload.isLogin
      state.isLoading = action.payload.isLoading
    }
  },
  extraReducers: {
    [getCredential.pending]: (state) => {
      state.isLoading = true
    },
    [getCredential.fulfilled]: (state, action) => {
      if (action.payload !== 'no token available') {
        http.defaults.headers.common['Authorization'] = `Bearer ${action.payload.access_token}`
        const stringValue = JSON.stringify(action.payload)
        const encodedData = encodedText(stringValue)
        cookies.set('impactor-session', encodedData, { path: '/' })        
        state.isLogin = true
        state.authorization = action.payload
        state.isLoading = false
      } else {
        cookies.remove('impactor-session')
        state.isLogin = false
        state.authorization = null
        state.isLoading = false
      }
    },
    [getCredential.rejected]: (state) => {
      state.isLoading = false
      state.isLogin = false
    }
  }
})

export const { update } = authData.actions
export default authData.reducer