import { configureStore } from '@reduxjs/toolkit'
import authData from '../store/authData'
import permissionLogin from '../store/permissionLogin'
import authUser from '../store/authUser'

export const store = configureStore({
  reducer: {
    auth: authData,
    permission: permissionLogin,
    user: authUser
  },
})
