import React from 'react'
import { Center, Loader } from '@mantine/core'

const LoadingData = () => {
  return (
    <Center h={500}>
      <Loader size='lg' type='bars' />
    </Center>
  )
}

export default LoadingData