import React, { useState, useEffect } from 'react'
import { Box, TextInput, PasswordInput, Select, Button, Progress, Popover, Group, Flex } from '@mantine/core'
import { getListRoles } from '../../../services/roles'
import { getListCompany } from '../../../services/company'
import { addUser, updateUser, updateUserPassword } from '../../../services/users'
import { validation } from '../../../plugins/validation'
import { notificationSuccess, notificationError } from '../../../components/ui/Notifications'
import PasswordRequired from '../../../components/ui/PasswordRequired'
import LoadingData from '../../ui/LoadingData'

const defaultVal = {
  fullName: '',
  email: '',
  roleId: null,
  companyId: null,
  password: '',
  verifyPassword: ''
}

const formValidation = {
  fullName: {
    isError: false,
    message: ''
  },
  email: {
    isError: false,
    message: ''
  },
  roleId: {
    isError: false,
    message: ''
  },
  companyId: {
    isError: false,
    message: false
  },
  password: {
    isError: false,
    message: ''
  },
  verifyPassword: {
    isError: false,
    message: ''
  }
}

const requirements = [
  { 
    re: /[0-9]/,
    label: 'Password harus berisikan nomor'
  },
  {
    re: /[a-z]/,
    label: 'Password harus berisikan huruf kecil'
  },
  {
    re: /[A-Z]/,
    label: 'Password harus berisikan huruf kapital'
  }
]

function getStrength(password) {
  let multiplier = password.length > 7 || password === '' ? 0 : 1;

  requirements.forEach((requirement) => {
    if (!requirement.re.test(password)) {
      multiplier += 1
    }
  })

  return Math.max(100 - (100 / (requirements.length + 1)) * multiplier, 10)
}

const FormUser = ({ dataUser, onCloseModal, reloadList, stateForm }) => {
  const [formData, setFormData] = useState(defaultVal)
  const [validationForm, setValidationForm] = useState(formValidation)
  const [loadingForm, setLoadingForm] = useState(false)
  const [loadingData, setLoadingData] = useState(true)
  const [roleList, setRoleList] = useState([])
  const [companyList, setCompanyList] = useState([])
  const [popoverPassword, setPopoverPassword] = useState(false)
  const checkPassword = requirements.map((val, index) => (
    <PasswordRequired key={index} label={val.label} meets={val.re.test(formData.password)} />
  ))
  const strengthPassword = getStrength(stateForm !== 'update' ? formData.password : [])

  const progressBar = Array(4).fill(0).map((_, index) => {
    return (
      <Progress 
        styles={{ section: { transitionDuration: '0ms' }}}
        value={Object.values(stateForm !== 'update' ? formData.password : []).length > 0 && index === 0 ? 100 : strengthPassword >= ((index + 1) / 4) * 100 ? 100 : 0}
        color={strengthPassword > 80 ? 'teal' : strengthPassword > 50 ? 'yellow' : 'red'}
        key={index}
        size={4}
      />
    )
  })

  const handleGetListRole = async () => {
    setLoadingData(true)
    try {
      const response = await getListRoles()
      const dataRole = response.data
      const mappingRoles = dataRole.map((val) => {
        return {
          value: val.id.toString(),
          label: val.name
        }
      })
      setRoleList(mappingRoles)
      await handleGetListCompany()
    } catch (error) {
      console.log(error)
    }
  }

  const handleGetListCompany = async () => {
    try {
      const response = await getListCompany()
      const dataCompany = response.data
      const mappingCompany = dataCompany.map((val) => {
        return {
          value: val.id.toString(),
          label: val.name
        }
      })
      setCompanyList(mappingCompany)
    } catch (error) {
      console.log(error)
    } finally {
      setLoadingData(false)
    }
  }

  useEffect(() => {
    if (dataUser !== null) {
      handleSetForm(dataUser)
    }
    // eslint-disable-next-line
  }, [dataUser])

  useEffect(() => {
    if (stateForm === 'change-password') {
      setLoadingData(false)
    } else {
      handleGetListRole()
    }
    // eslint-disable-next-line
  }, [stateForm])

  const handleSetForm = (val) => {
    const dataDetailUser = {
      id: val.id,
      fullName: val.fullName,
      nickname: val.nickname,
      email: val.email,
      roleId: val.role ? val.role.id : '',
      companyId: val.company ? val.company.id : '',
      password: '',
      verifyPassword: ''
    }
    setFormData(dataDetailUser)
  }

  const handleChangeForm = (name, val) => {
    setFormData((oldVal) => ({ ...oldVal, [name]: val}))
  }

  const submitUser = async (formUser) => {
    setLoadingForm(true)
    let methodFunction = null
    let titleMessageSuccess = ''
    let captionMessageSuccess = ''
    let titleMessageError = ''
    let captionMessageError = ''
    setValidationForm(formValidation)
    let payload = {}
    if (stateForm === 'create') {
      if (formData.password !== formData.verifyPassword) {
        setValidationForm((old) => ({
          ...old,
          verifyPassword: {
            isError: true,
            message: 'Konfirmasi password harus sama dengan password anda'
          }
        }))
        setLoadingForm(false)
        return 
      }
    }
    if (stateForm === 'create') {
      payload = {
        fullName: formUser.fullName,
        email: formUser.email,
        roleId: formUser.roleId,
        companyId: formUser.companyId,
        password: formUser.verifyPassword
      }
      titleMessageSuccess = 'Tambah pengguna Berhasil'
      captionMessageSuccess = 'Anda telah berhasil menambahkan pengguna baru'
      titleMessageError = 'Gagal Menambahkan Pengguna'
    } else if (stateForm === 'update') {
      delete formData.password
      delete formData.verifyPassword
      delete formData.companyId
      payload = {
        fullName: formUser.fullName,
        email: formUser.email,
        roleId: formUser.roleId
      }
      titleMessageSuccess = 'Update Pengguna Berhasil'
      captionMessageSuccess = 'Anda telah berhasil mengupdate pengguna'
      titleMessageError = 'Gagal Mengupdate Pengguna'
    } else if (stateForm === 'change-password') {
      delete formData.fullName
      delete formData.email
      delete formData.companyId
      delete formData.roleId
      payload = {
        oldPassword: formUser.verifyPassword,
        newPassword: formUser.password
      }
      titleMessageSuccess = 'Update Password Pengguna Berhasil'
      captionMessageSuccess = 'Anda telah berhasil mengupdate password pengguna'
      titleMessageError = 'Gagal Mengupdate Password Pengguna'
    }
    const isError = validation(stateForm !== 'change-password' ? payload : formData, setValidationForm)
    if (isError) {
      setLoadingForm(false)
      return
    }
    if (stateForm === 'create') {
      methodFunction = addUser(payload)
    } else if (stateForm === 'update') {
      methodFunction = updateUser(formUser.id, payload)
    } else if (stateForm === 'change-password') {
      methodFunction = updateUserPassword(formUser.id, payload)
    }
    try {
      const response = await methodFunction
      if (response) {
        onCloseModal()
        reloadList()
        notificationSuccess(titleMessageSuccess, captionMessageSuccess)
        setLoadingForm(false)
      }
    } catch (error) {
      setLoadingForm(false)
      const errorMessage = error.response.data.message
      captionMessageError = Object.keys(errorMessage) ? errorMessage : 'Silahkan cek kembali form anda'
      notificationError(titleMessageError, captionMessageError)
      Object.values(errorMessage).forEach((el) => {
        Object.keys(formValidation).forEach((element) => {
          if (el.includes(element)) {
            setValidationForm((old) => ({
              ...old,
              [element]: {
                ...old?.[element],
                isError: true,
                message: el
              }
            }))
          }
        })
      })
    }
  }

  const mappingFormUser = (state) => {
    if (state === 'create') {
      return (
        <Box>
          <Box mb='md'>
            <TextInput
              name='fullName'
              value={formData.fullName}
              label='Nama'
              placeholder='Masukkan nama'
              error={validationForm.fullName.isError ? `${validationForm.fullName.message}` : ''}
              onChange={(val) => handleChangeForm('fullName', val.target.value)}
              withAsterisk
            />
          </Box>
          <Box mb='md'>
            <TextInput
              name='email'
              value={formData.email}
              label='Email'
              placeholder='Masukkan email'
              error={validationForm.email.isError ? `${validationForm.email.message}` : ''}
              onChange={(val) => handleChangeForm('email', val.target.value)}
              withAsterisk
              disabled={dataUser !== null}
            />
          </Box>
          <Box mb='md'>
            <Select
              name="roleId"
              label="Jabatan Pengguna"
              placeholder="Pilih salah satu role"
              searchable
              nothingFoundMessage='Jabatan tidak ditemukan'
              data={roleList}
              onChange={(e) => handleChangeForm('roleId', e)}
              error={validationForm.roleId.isError ? `${validationForm.roleId.message}` : ''}
              value={formData.roleId}
              withAsterisk
              checkIconPosition="right"
              allowDeselect={false}
            />
          </Box>
          <Box mb='md'>
            <Select
              name="companyId"
              label="Perusahaan"
              placeholder="Pilih salah satu perusahaan"
              searchable
              nothingFoundMessage='Perusahaan tidak ditemukan'
              data={companyList}
              onChange={(e) => handleChangeForm('companyId', e)}
              error={validationForm.companyId.isError ? `${validationForm.companyId.message}` : ''}
              value={formData.companyId}
              withAsterisk
              checkIconPosition="right"
              allowDeselect={false}
            />
          </Box>
          <Box mb="md">
            <Popover opened={popoverPassword} position="bottom" width="target" transitionProps={{ transition: 'pop' }}>
              <Popover.Target>
                <Box onFocusCapture={() => setPopoverPassword(true)} onBlurCapture={() => setPopoverPassword(false)}>
                  <PasswordInput
                    name="password"
                    value={formData.password}
                    placeholder="Masukkan password"
                    label="Password"
                    error={validationForm.password.isError ? `${validationForm.password.message}` : ''}
                    onChange={(val) => handleChangeForm('password', val.target.value)}
                    withAsterisk
                  />
                </Box>
              </Popover.Target>
              <Popover.Dropdown>
                <Group gap={5} grow mt="xs" mb="md">
                  {progressBar}
                </Group>
                <PasswordRequired label="Password harus lebih dari 7 karakter" meets={formData.password.length > 7} />
                {checkPassword}
              </Popover.Dropdown>
            </Popover>
          </Box>
          <Box mb="md">
            <PasswordInput
              value={formData.verifyPassword}
              name="verifyPassword"
              placeholder="Ulangi password anda"
              label="Konfirmasi Password"
              error={validationForm.verifyPassword.isError ? validationForm.verifyPassword.message : ''}
              onChange={(val) => handleChangeForm('verifyPassword', val.target.value)}
              withAsterisk
            />
          </Box>
        </Box>
      )
    } else if (state === 'update') {
      return (
        <Box>
          <Box mb='md'>
            <TextInput
              name='fullName'
              value={formData.fullName}
              label='Nama'
              placeholder='Masukkan nama'
              error={validationForm.fullName.isError ? `${validationForm.fullName.message}` : ''}
              onChange={(val) => handleChangeForm('fullName', val.target.value)}
              withAsterisk
            />
          </Box>
          <Box mb='md'>
            <TextInput
              name='email'
              value={formData.email}
              label='Email'
              placeholder='Masukkan email'
              error={validationForm.email.isError ? `${validationForm.email.message}` : ''}
              onChange={(val) => handleChangeForm('email', val.target.value)}
              withAsterisk
              disabled={dataUser !== null}
            />
          </Box>
          <Box mb='md'>
            <Select
              name="roleId"
              label="Jabatan Pengguna"
              placeholder="Pilih salah satu role"
              searchable
              nothingFoundMessage='Jabatan tidak ditemukan'
              data={roleList}
              onChange={(e) => handleChangeForm('roleId', e)}
              error={validationForm.roleId.isError ? `${validationForm.roleId.message}` : ''}
              value={formData.roleId}
              withAsterisk
              checkIconPosition="right"
            />
          </Box>
          <Box mb='md'>
            <Select
              name="companyId"
              label="Perusahaan"
              placeholder="Pilih salah satu perusahaan"
              searchable
              nothingFoundMessage='Perusahaan tidak ditemukan'
              data={companyList}
              onChange={(e) => handleChangeForm('companyId', e)}
              error={validationForm.companyId.isError ? `${validationForm.companyId.message}` : ''}
              value={formData.companyId}
              withAsterisk
              checkIconPosition="right"
              disabled={dataUser !== null}
            />
          </Box>
        </Box>
      )
    } else if (state === 'change-password') {
      return (
        <Box>
          <Box mb="md">
            <PasswordInput
              value={formData.verifyPassword}
              name="verifyPassword"
              placeholder="Masukkan password"
              label="Masukkan Password Lama"
              error={validationForm.verifyPassword.isError ? validationForm.verifyPassword.message : ''}
              onChange={(val) => handleChangeForm('verifyPassword', val.target.value)}
              withAsterisk
            />
          </Box>
          <Box mb="md">
            <Popover opened={popoverPassword} position="bottom" width="target" transitionProps={{ transition: 'pop' }}>
              <Popover.Target>
                <Box onFocusCapture={() => setPopoverPassword(true)} onBlurCapture={() => setPopoverPassword(false)}>
                  <PasswordInput
                    name="password"
                    value={formData.password}
                    placeholder="Masukkan password "
                    label="Masukkan Password Baru"
                    error={validationForm.password.isError ? `${validationForm.password.message}` : ''}
                    onChange={(val) => handleChangeForm('password', val.target.value)}
                    withAsterisk
                  />
                </Box>
              </Popover.Target>
              <Popover.Dropdown>
                <Group gap={5} grow mt="xs" mb="md">
                  {progressBar}
                </Group>
                <PasswordRequired label="Password harus lebih dari 7 karakter" meets={formData.password.length > 7} />
                {checkPassword}
              </Popover.Dropdown>
            </Popover>
          </Box>
        </Box>
      )
    }
  }

  return (
    <Box>
      {loadingData ? <LoadingData /> : mappingFormUser(stateForm)}
      <Box mt={20}>
        <Flex justify='flex-end'>
          <Group>
            <Button size='xs' variant="outline" onClick={onCloseModal}>Tutup</Button>
            <Button size='xs' loading={loadingForm} variant="filled" onClick={() => submitUser(formData)}>{dataUser === null ? 'Tambah' : 'Update'}</Button>
          </Group>
        </Flex>
      </Box>
    </Box>
    
  )
}

export default FormUser

