const MENU_PERMISSION = {
  dashboard: 'dashboard',
  company: 'company',
  industry: 'industry',
  permission: 'permission',
  user: 'user',
  role: 'role',
  setting: 'setting'
}

export { MENU_PERMISSION }