// public route
import Login from './pages/authentication/Login'

// administrator route
import Dashboard from './pages/administrator/Dashboard'
import Company from './pages/administrator/Company'
import Industry from './pages/administrator/Industry'
import Role from './pages/administrator/Role'
import Users from './pages/administrator/Users'
import Setting from './pages/administrator/Setting'

// icon sidebar route
import { 
  IconGauge,
  IconUser,
  IconSettings,
  IconCategory,
  IconUserCheck,
  IconBuildingFactory,
} from "@tabler/icons-react"

// permission
import { MENU_PERMISSION } from './plugins/helper'


const routes = [
  {
    name: 'login-page',
    route: '/login',
    hasChildren: false,
    component: <Login />
  },
  {
    name: 'dashboard',
    route: '/',
    hasChildren: false,
    component: <Dashboard />,
  },
]

const authRoutes = [
  {
    name: 'dashboard',
    route: '/dashboard',
    hasChildren: false,
    component: <Dashboard />,
    icon: <IconGauge stroke={1.5} size={18} />,
    type: 'route',
    group: null,
    permission: MENU_PERMISSION.dashboard
  },
  {
    name: 'industri',
    type: 'title',
    group: null,
    permission: MENU_PERMISSION.company
  },
  {
    name: 'sektor perusahaan',
    route: '/corporate-sector',
    hasChildren: false,
    component: <Industry />,
    icon: <IconCategory stroke={1.5} size={18} />,
    type: 'route',
    group: 'company',
    permission: MENU_PERMISSION.industry
  },
  {
    name: 'perusahaan',
    route: '/company',
    hasChildren: false,
    component: <Company />,
    icon: <IconBuildingFactory stroke={1.5} size={18} />,
    type: 'route',
    group: 'company',
    permission: MENU_PERMISSION.company
  },
  {
    name: 'lanjutan',
    type: 'title',
    group: null,
    permission: MENU_PERMISSION.user
  },
  {
    name: 'pengguna',
    route: '/users',
    hasChildren: false,
    component: <Users />,
    icon: <IconUser stroke={1.5} size={18} />,
    type: 'route',
    group: 'user',
    permission: MENU_PERMISSION.user
  },
  {
    name: 'jabatan pengguna',
    route: '/roles',
    hasChildren: false,
    component: <Role />,
    icon: <IconUserCheck stroke={1.5} size={18} />,
    type: 'route',
    group: 'user',
    permission: MENU_PERMISSION.role
  },
  {
    name: 'setelan',
    route: '/setting',
    hasChildren: false,
    component: <Setting />,
    icon: <IconSettings stroke={1.5} size={18} />,
    type: 'route',
    group: null,
    permission: MENU_PERMISSION.setting
  },
]

export { routes, authRoutes }