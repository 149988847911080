import React from 'react'
import { useSelector } from 'react-redux'
import { authRoutes } from '../../../../routes'
import { Box, ThemeIcon, Text, Center, Loader, NavLink } from '@mantine/core'
import { useNavigate, useLocation } from 'react-router-dom'
import { useViewportSize } from '@mantine/hooks'

const LoadingData = () => {
  const { height } = useViewportSize()
  return (
    <Center h={height / 1.2}>
      <Loader size='sm' type='bars' />
    </Center>
  )
}

const AppSidebar = () => {
  const navigate = useNavigate()
  const { pathname } = useLocation()
  const { isLoading, access } = useSelector(state => state.permission)

  const camelToDashCase = (str) => {
    if (str) {
      return str.replace(/([a-z])([A-Z])/g, '$1-$2').toLowerCase()
    } else {
      return str
    }
  }

  const mappingMenuSidebar = (listPermission) => {
    if (listPermission !== null) {
      // eslint-disable-next-line
      const renderRoute = authRoutes.map(({ name, route, icon, type, permission }, index) => {
        let returnRender = null
        const dataPermission = Object.keys(listPermission)
        dataPermission.push('dashboard')
        dataPermission.push('setting')

        const isAllowed = dataPermission.includes(camelToDashCase(permission))
        if (type === 'route') {
          returnRender = (
            <NavLink 
              label={
                <span 
                  style={{ 
                    textTransform: 'capitalize',
                    fontSize: '13px', 
                    color: route === pathname ? '' : 'rgb(174, 176, 179)', 
                    fontWeight: route === pathname ? '600' : ''
                  }}
                >
                  {name}
                </span>
              } 
              leftSection={<ThemeIcon color={route === pathname ? '' : 'gray.5'} variant='light' radius="md" size="md">{icon}</ThemeIcon>}
              active={route === pathname || undefined}
              mb={8}
              onClick={(event) => {
                event.preventDefault()
                navigate(`${route}`)
              }}
              style={{ fontWeight: route === pathname ? '500' : '' }}
            />
          )
        } else if (type === 'title') {
          returnRender = (
            <Text fz={12} fw='bold' c='gray.5' ml={20} tt='uppercase' my={12} key={index} >{name}</Text>
          )
        }

        if (permission) {
          if (isAllowed) {
            return returnRender
          } else {
            return null
          }
        }
      })
      return renderRoute
    }
  }
  return (
    <Box p='lg'>
      {isLoading ? (<LoadingData />) : mappingMenuSidebar(access)}
    </Box>
  )
}

export default AppSidebar