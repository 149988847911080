import React, { useState, useEffect } from 'react'
import { Box, TextInput, Flex, Group, Button, Select, Textarea, Input } from '@mantine/core'
import { getListProvince, getListCity } from '../../../services/location'
import { getListIndustries } from '../../../services/industries'
import { addCompany, updateCompany } from '../../../services/company'
import { IMaskInput } from 'react-imask'
import { validation } from '../../../plugins/validation'
import { notificationSuccess, notificationError } from '../../../components/ui/Notifications'
import LoadingData from '../../ui/LoadingData'


const defaultVal = {
  name: '',
  alias: '',
  address: '',
  phone: '',
  provinceId: null,
  cityId: null,
  industryId: null,
}

const formValidation = {
  name: {
    isError: false,
    message: ''
  },
  alias: {
    isError: false,
    message: ''
  },
  address: {
    isError: false,
    message: ''
  },
  phone: {
    isError: false,
    message: ''
  },
  provinceId: {
    isError: false,
    message: ''
  },
  cityId: {
    isError: false,
    message: ''
  },
  industryId: {
    isError: false,
    message: false
  },
}

const FormCompany = ({ dataCompany, onCloseModal, reloadList }) => {
  const [formData, setFormData] = useState(defaultVal)
  const [validationForm, setValidationForm] = useState(formValidation)
  const [loadingForm, setLoadingForm] = useState(false)
  const [loadingData, setLoadingData] = useState(true)
  const [provinceList, setProvinceList] = useState([])
  const [cityList, setCityList] = useState([])
  const [industryList, setIndustryList] = useState([])

  const handleGetListProvince = async () => {
    setLoadingData(true)
    try {
      const response = await getListProvince()
      const dataProvince = response.data
      const mappingProvince = dataProvince.map((val) => {
        return {
          value: val.id.toString(),
          label: val.name
        }
      })
      setProvinceList(mappingProvince)
      await handleGetListIndustry()
    } catch (error) {
      console.log(error)
    }
  }

  const handleGetListCity = async (cityId) => {
    const params = {
      province: cityId
    }
    try {
      const response = await getListCity(params)
      const dataCity = response.data
      const mappingCity = dataCity.map((val) => {
        return {
          value: val.id.toString(),
          label: val.name
        }
      })
      setCityList(mappingCity)
    } catch (error) {
      console.log(error)
    }
  }

  const handleGetListIndustry = async () => {
    try {
      const response = await getListIndustries()
      const dataIndustry = response.data
      const mappingIndustry = dataIndustry.map((val) => {
        return {
          value: val.id.toString(),
          label: val.name
        }
      })
      setIndustryList(mappingIndustry)
    } catch (error) {
      console.log(error)
    } finally {
      setLoadingData(false)
    }
  }

  useEffect(() => {
    handleGetListProvince()
    if (dataCompany !== null) {
      handleSetForm(dataCompany)
    }
    // eslint-disable-next-line
  },[])

  useEffect(() => {
    if (formData.provinceId !== null) {
      handleGetListCity(formData.provinceId)
    } else {
      
    }
    // eslint-disable-next-line
  },[formData.provinceId])

  const handleSetForm = (detail) => {
    const form = {
      id: detail.id,
      name: detail.name,
      alias: detail.alias,
      address: detail.address,
      phone: detail.phone,
      provinceId: detail.city.province.id.toString(),
      cityId: detail.city.id.toString(),
      industryId: detail.industry.id,
    }
    setFormData(form)
  }

  const handleChangeForm = (name, val) => {
    setFormData((oldVal) => ({ ...oldVal, [name]: val}))
  }

  const submitCompany = async (val) => {
    let methodFunction = null
    let titleMessageSuccess = ''
    let captionMessageSuccess = ''
    let titleMessageError = ''
    let captionMessageError = ''
    setLoadingForm(true)
    setValidationForm(formValidation)
    const payload = {
      name: val.name,
      alias: val.alias,
      phone: val.phone,
      address: val.address,
      cityId: val.cityId,
      industryId: val.industryId
    }
    const isError = validation(formData, setValidationForm)
    if (isError) {
      setLoadingForm(false)
      return
    }
    if (dataCompany !== null) {
      methodFunction = updateCompany(val.id, payload)
      titleMessageSuccess = 'Update Perusahaan Berhasil'
      captionMessageSuccess = 'Anda telah berhasil mengupdate data perusahaan'
      titleMessageError = 'Gagal Mengupdate Perusahaan'
      captionMessageError = 'Silahkan cek kembali form anda'
    } else {
      methodFunction = addCompany(payload)
      titleMessageSuccess = 'Tambah Perusahaan Berhasil'
      captionMessageSuccess = 'Anda telah berhasil menambahkan perusahaan baru'
      titleMessageError = 'Gagal Menambahkan Perusahaan'
      captionMessageError = 'Silahkan cek kembali form anda'
    }
    try {
      const response = await methodFunction
      if (response) {
        onCloseModal()
        reloadList()
        notificationSuccess(titleMessageSuccess, captionMessageSuccess)
      }
    } catch (error) {
      const errorMessage = error.response.data.message
      notificationError(titleMessageError, `${Object.keys(errorMessage) ? errorMessage : captionMessageError}`)
      Object.values(errorMessage).forEach((el) => {
        Object.keys(formValidation).forEach((element) => {
          if (el.includes(element)) {
            setValidationForm((old) => ({
              ...old,
              [element]: {
                ...old?.[element],
                isError: true,
                message: el
              }
            }))
          }
        })
      })
    } finally {
      setLoadingForm(false)
    }
  }

  const loadFormCompany = () => {
    return (
      <Box>
        <Box mb='md'>
          <TextInput
            name='name'
            value={formData.name}
            label='Nama Perusahaan'
            placeholder='Masukkan nama perusahaan'
            error={validationForm.name.isError ? `${validationForm.name.message}` : ''}
            onChange={(val) => handleChangeForm('name', val.target.value)}
            withAsterisk
          />
        </Box>
        <Box mb='md'>
          <TextInput
            name='alias'
            value={formData.alias}
            label='Kode Perusahaan'
            placeholder='Masukkan kode perusahaan'
            error={validationForm.alias.isError ? `${validationForm.alias.message}` : ''}
            onChange={(val) => handleChangeForm('alias', val.target.value)}
            withAsterisk
          />
        </Box>
        <Box mb='md'>
          <Input.Wrapper 
            label="Kontak Perusahaan"
            error={validationForm.phone.isError ? `${validationForm.phone.message}` : ''}
            withAsterisk
            mb={10}
          >
            <Input
              name="phone"
              value={formData.phone}
              placeholder="Isi kontak perusahaan"
              component={IMaskInput}
              mask="+620000000000000"
              onChange={(val) => handleChangeForm('phone', val.target.value)}
            />
          </Input.Wrapper>
        </Box>
        <Box mb='md'>
          <Select
            name="provinceId"
            label="Provinsi"
            placeholder="Pilih salah satu provinsi"
            searchable
            nothingFoundMessage='Provinsi tidak ditemukan'
            data={provinceList}
            onChange={(e) => [handleChangeForm('provinceId', e), handleChangeForm('cityId', null)]}
            error={validationForm.provinceId.isError ? `${validationForm.provinceId.message}` : ''}
            value={formData.provinceId}
            withAsterisk
            checkIconPosition="right"
            clearable
          />
        </Box>
        <Box mb='md'>
          <Select
            name="cityId"
            label="Kota"
            placeholder="Pilih salah satu kota"
            searchable
            nothingFoundMessage='Kota tidak ditemukan'
            data={cityList}
            onChange={(e) => handleChangeForm('cityId', e)}
            error={validationForm.cityId.isError ? `${validationForm.cityId.message}` : ''}
            value={formData.cityId}
            withAsterisk
            checkIconPosition="right"
            clearable
            disabled={formData.provinceId === null}
          />
        </Box>
        <Box mb='md'>
          <Textarea
            name='adress'
            value={formData.address}
            label='Alamat Perusahaan'
            placeholder='Masukkan alamat perusahaan'
            error={validationForm.address.isError ? `${validationForm.address.message}` : ''}
            onChange={(val) => handleChangeForm('address', val.target.value)}
            withAsterisk
            autosize
            minRows={4}
            maxRows={4}
          />
        </Box>
        <Box mb='md'>
          <Select
            name="industryId"
            label="Sektor Perusahaan"
            placeholder="Pilih salah satu sektor"
            searchable
            nothingFoundMessage='Sektor Perusahaan tidak ditemukan'
            data={industryList}
            onChange={(e) => handleChangeForm('industryId', e)}
            error={validationForm.industryId.isError ? `${validationForm.industryId.message}` : ''}
            value={formData.industryId}
            withAsterisk
            checkIconPosition="right"
            clearable
          />
        </Box>
      </Box>
    )
  }
  

  return (
    <Box>
      {loadingData ? <LoadingData /> : loadFormCompany()}
      <Box mt={20}>
        <Flex justify='flex-end'>
          <Group>
            <Button size='xs' variant="outline" onClick={onCloseModal}>Tutup</Button>
            <Button size='xs' loading={loadingForm} variant="filled" onClick={() => submitCompany(formData)}>{dataCompany === null ? 'Tambah' : 'Update'}</Button>
          </Group>
        </Flex>
      </Box>
    </Box>
  )
}

export default FormCompany