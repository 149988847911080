import React, { useEffect } from 'react'
import { createTheme, MantineProvider, LoadingOverlay } from '@mantine/core'
import { Notifications } from '@mantine/notifications'
import { ModalsProvider } from '@mantine/modals'
import { Routes, Route } from "react-router-dom"
import { routes, authRoutes } from './routes'
import { useLocalStorage, readLocalStorageValue, useHotkeys } from '@mantine/hooks'
import { getCredential } from './store/authData'
import { getPermissionLogin } from './store/permissionLogin'
import { getAuthUser } from './store/authUser'
import { useSelector, useDispatch } from 'react-redux'
import { useNavigate, useLocation } from 'react-router-dom'
import Cookies from "universal-cookie"

// css mantine
import '@mantine/core/styles.css'
import '@mantine/notifications/styles.css'
import '@mantine/dates/styles.css'



export default function App() {
  const cookies = new Cookies()
  const navigate = useNavigate()
  const dispatch = useDispatch()
  const { pathname } = useLocation()
  const { isLoading, isLogin } = useSelector(state => state.auth)
  const themeValue = readLocalStorageValue({ key: 'impactor-app-theme' })
  const layoutValue = readLocalStorageValue({ key: 'impactor-layout' })
  const [, setThemeApp] = useLocalStorage({
    key: 'impactor-app-theme',
    defaultValue: themeValue ? themeValue : 'light'
  })

  const [, setLayout] = useLocalStorage({
    key: 'impactor-layout',
    defaultValue: layoutValue ? layoutValue : 'nav-sidebar'
  })

  const defaultPrimaryColor = ['#EBFBEE', '#D3F9D8', '#B2F2BB', '#8CE99A', '#69DB7C', '#51CF66', '#40C057', '#37B24D', '#2F9E44', '#2B8A3E']

  const styleUI = createTheme({
    primaryColor: 'green',
    colors: { 
      'green': defaultPrimaryColor 
    }, 
    fontFamily: "Montserrat, sans-serif",
    headings: { 
      fontFamily: 'Montserrat, sans-serif'
    }
  })

  const handleChangeTheme = () => {
    setThemeApp((current) => current === 'dark' ? 'light' : 'dark')
  }

  // shortcut hotkeys theme 
  useHotkeys([
    ['mod+J', () => handleChangeTheme()]
  ])

  // update credential
  useEffect(() => {
    dispatch(getCredential())
    setLayout((current) => current === 'nav-sidebar' ? 'nav-sidebar' : 'nav-header')
  }, [dispatch, setLayout])

  
  useEffect(() => {
    if (!isLoading) {
      if (isLogin) {
        dispatch(getPermissionLogin())
        dispatch(getAuthUser())
        return navigate(pathname !== '/login' ? pathname : '/dashboard')
      } else {
        cookies.remove('impactor-session')
        return navigate('/login', { state: { from: pathname }, replace: true})
      }
    }
    // eslint-disable-next-line
  }, [isLogin])

  // eslint-disable-next-line
  const getRoute = (allRoutes) => allRoutes.map((page) => {
    if (!page.hasChildren) {
      return (
        <Route path={page.route} element={page.component}  key={page.name} />
      )
    } else {
      return (
        <Route path={page.route} key={page.name}>
          {childrenRoute(page.children)}
        </Route>
      )
    }
  })

  // eslint-disable-next-line
  const childrenRoute = (arrayRoute) => arrayRoute.map((children) => {
    if (children) {
      if (children.route === null) {
        return (
          <Route index element={children.component} key={children.name} />
        )
      } else {
        return (
          <Route path={children.route} element={children.component} key={children.name} />
        )
      }
    }
  })

  return (
    <MantineProvider forceColorScheme={themeValue} theme={styleUI}>
      <LoadingOverlay visible={isLoading} zIndex={1000} overlayProps={{ radius: "md", blur: 3 }} />
      <Notifications autoClose={4000} position="top-right" limit={5} />
      <ModalsProvider>
      <Routes>
          {getRoute(routes)}
          {getRoute(authRoutes)}
        </Routes>
      </ModalsProvider>
    </MantineProvider>
  )
}
