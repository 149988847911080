import React from 'react'
import { Table, Badge, Menu, ActionIcon, Tooltip } from '@mantine/core'
import { IconDotsVertical, IconTrash, IconPencil, IconListCheck, IconInfoCircle } from '@tabler/icons-react'
import { useSelector } from 'react-redux' 

const ActionMenu = ({ handleAction }) => {
  const permissionState = useSelector(state => state.permission.access)

  const permissionUpdate = () => {
    const permissionRole = permissionState['role']
    const updatedPermission = permissionRole.find(val => val.alias === 'role.update')
    if (updatedPermission !== undefined) {
      return (
        <Menu.Item
          leftSection={<IconPencil stroke={1.5} size={14} />}
          onClick={() => handleAction('edit')}
          fw='600'
          fz={11}
        >
          Edit
        </Menu.Item>
      )
    }
  }

  const permissionAccess = () => {
    const accessPermission = permissionState['permission']
    if (accessPermission !== undefined) {
      return (
        <Menu.Item
          leftSection={<IconListCheck stroke={1.5} size={14} />}
          onClick={() => handleAction('permission')}
          fw='600'
          fz={11}
        >
          Akses Jabatan
        </Menu.Item>
      )
    }
  }

  const permissionDelete = () => {
    const permissionRole = permissionState['role']
    const deletePermission = permissionRole.find(val => val.alias === 'role.delete')
    if (deletePermission !== undefined) {
      return (
        <Menu.Item
          color='red'
          leftSection={<IconTrash stroke={1.5} size={14} />}
          onClick={() => handleAction('delete')}
          fw='600'
          fz={11}
        >
          Hapus
        </Menu.Item>
      )
    }
  }

  return (
    <Menu width={150} position="bottom-end" withArrow arrowPosition="center">
      <Menu.Target>
        <ActionIcon ml={10} mt={6} variant='transparent' size='sm'>
          <IconDotsVertical stroke={1.5} />
        </ActionIcon>
      </Menu.Target>
      <Menu.Dropdown>
        {permissionState !== null ? permissionUpdate() : ''}
        {permissionState !== null ? permissionAccess() : ''}
        {permissionState !== null ? permissionDelete() : ''}
      </Menu.Dropdown>
    </Menu>
  )
}

const TableRoles = ({ label, data, actionMethod }) => {
  const permissionState = useSelector(state => state.permission.access)

  const mappingTableHead = (data) => data.map((val, index) => {
    return (<Table.Th key={index} width={val.width !== 'auto' ? val.width : ''}>{val.label}</Table.Th>)
  })

  const mappingDataTable = data => data.map((val, index) => {
    const mappingMenu = () => {
      const permissionRole = permissionState['role']
      const permissionAccess = permissionState['permission']
      const updatedPermission = permissionRole.find(val => val.alias === 'role.update')
      
      const deletedPermission = permissionRole.find(val => val.alias === 'role.delete')
      if (updatedPermission === undefined && deletedPermission === undefined && permissionAccess === undefined) {
        return (
          <Tooltip label='Anda tidak diizinkan untuk mengakses menu ini'>
            <ActionIcon ml={10} mt={6} variant='transparent' size='xs'>
              <IconInfoCircle stroke={1.5}/>
            </ActionIcon>
          </Tooltip>
        )
      } else {
        return (<ActionMenu handleAction={(type) => actionMethod(val, type)} />)
      }
    }

    return (
      <Table.Tr key={index}>
        <Table.Td>{index + 1}.</Table.Td>
        <Table.Td>{val.name}</Table.Td>
        <Table.Td><Badge size="xs" radius="sm" variant="filled" color={val.isActive ? 'green' : 'red'}>{val.isActive ? 'Active' : 'Non Active'}</Badge></Table.Td>
        <Table.Td>{permissionState !== null ? mappingMenu() : ''}</Table.Td>
      </Table.Tr>
    )
  })
  return (
    <Table.ScrollContainer minWidth={768}>
      <Table highlightOnHover withTableBorder style={{ fontSize: '12px' }}>
        <Table.Thead>
          <Table.Tr>
            {mappingTableHead(label)}
          </Table.Tr>
        </Table.Thead>
        <Table.Tbody>
          {mappingDataTable(data)}
        </Table.Tbody>
      </Table>
    </Table.ScrollContainer>
  )
}

export default TableRoles