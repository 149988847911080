import http from '../plugins/axios'

export const getListProvince = async (params) => {
  try {
    const response = await http.get(`/provinces`, { params })
    return response.data
  } catch(error) {
    throw error
  }
}

export const getListCity = async (params) => {
  try {
    const response = await http.get(`/cities`, { params })
    return response.data
  } catch(error) {
    throw error
  }
}