import React from 'react'
import AuthLayout from '../../../layout/AuthLayout'

const Dashboard = () => {
  return (
    <AuthLayout>
      <div>Dashboard</div>
    </AuthLayout>
  )
}

export default Dashboard