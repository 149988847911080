import React from 'react'
import { Box, Text, Card, Grid, Image, Radio, CheckIcon, Flex, Button } from '@mantine/core'
import navHeaderLogo from '../../../assets/img/layoutHorizontal.svg'
import navSidebarLogo from '../../../assets/img/layoutVertical.svg'
import {useLocalStorage ,readLocalStorageValue } from '@mantine/hooks'

const Layout = ({ onClose }) => {
  const layoutValue = readLocalStorageValue({ key: 'impactor-layout' })
  const [, setLayout] = useLocalStorage({
    key: 'impactor-layout',
    defaultValue: layoutValue !== undefined ? layoutValue : 'nav-sidebar'
  })
  
  const handleChangeLayout = () => {
    setLayout((current) => current === 'nav-sidebar' ? 'nav-header' : 'nav-sidebar')
  }

  return (
    <Box>
      <Text fz={12} fw={500}>Ganti Tampilan Sistem</Text>
      <Box mt={20}>
        <Grid>
          <Grid.Col span={3}>
            <Card shadow="sm" radius="md" withBorder>
              <Box ta='center'>
                <Image
                  src={navSidebarLogo}
                  height={100}
                  alt="nav-sidebar"
                  fit="contain"
                />
                <Box ta='center' mt={8}>
                  <Text>Bilah Sisi</Text>
                  <Flex justify='center'>
                    <Radio icon={CheckIcon} checked={layoutValue === 'nav-sidebar'} onChange={handleChangeLayout} />
                  </Flex>
                </Box>
              </Box>
            </Card>
          </Grid.Col>
          <Grid.Col span={3}>
            <Card shadow="sm" radius="md" withBorder>
              <Box ta='center'>
                <Image
                  src={navHeaderLogo}
                  height={100}
                  alt="dark-header"
                  fit="contain"
                />
                <Box ta='center' mt={8}>
                  <Text>Bilah Navigasi</Text>
                  <Flex justify='center'>
                    <Radio icon={CheckIcon} checked={layoutValue === 'nav-header'} onChange={handleChangeLayout} />
                  </Flex>
                </Box>
              </Box>
            </Card>
          </Grid.Col>
        </Grid>
      </Box>
      <Box mt={50}>
        <Box ta='center'>
          <Button onClick={onClose}>Kembali</Button>
        </Box>
      </Box>
    </Box>
  )
}

export default Layout