import React, { useState } from 'react'
import classes from './Login.module.css'
import { Box, Button, Grid, Text, TextInput, PasswordInput, Anchor, Center, Card, Image } from '@mantine/core'
import { useViewportSize } from '@mantine/hooks'
import { validation } from '../../../plugins/validation'
import { login } from '../../../services/authorization'
import { notificationSuccess, notificationError } from '../../../components/ui/Notifications'
import ImpactorLogo from '../../../assets/img/impactor-logo.png'
import PETLogo from '../../../assets/img/PET_logo.png'
import { useDispatch } from 'react-redux'
import { update } from '../../../store/authData.js'
import http from '../../../plugins/axios.js'
import { encodedText } from '../../../plugins/encrypt.js'
import Cookies from 'universal-cookie'
import { useNavigate } from 'react-router-dom'
import { getPermissionLogin } from '../../../store/permissionLogin'
import { getAuthUser } from '../../../store/authUser'


const defaultVal = {
  company: '',
  email: '',
  password: ''
}

const formValidation = {
  company: {
    isError: false,
    message: ''
  },
  email: {
    isError: false,
    message: ''
  },
  password: {
    isError: false,
    message: ''
  }
}

const Login = () => {
  const cookies = new Cookies()
  const navigate = useNavigate()
  const dispatch = useDispatch()
  const { width, height } = useViewportSize()
  const [ formData, setFormData ] = useState(defaultVal)
  const [ validationForm, setValidationForm ] = useState(formValidation)
  const [ loading, setLoading ] = useState(false)

  const handleChange = (e) => {
    setFormData({ ...formData, [e.target.name]: e.target.value })
  }
  const handleSubmit = async (e) => {
    e.preventDefault()
    setLoading(true)
    setValidationForm(formValidation)
    const isErrorForm = validation(formData, setValidationForm)
    if (isErrorForm) {
      setLoading(false)
      return
    }
    try {
      const response = await login(formData)
      if (response) {
        notificationSuccess('Login Berhasil', `Selamat datang`)
        http.defaults.headers.common['Authorization'] = `Bearer ${response.access_token}`
        const stringValue = JSON.stringify(response)
        const encodedData = encodedText(stringValue)
        cookies.set('impactor-session', encodedData, { path: '/' })
        dispatch(update({ 'authorization': response, 'isLogin': true, 'isLoading': false }))
        dispatch(getPermissionLogin())
        dispatch(getAuthUser())
        return navigate('/dashboard')
      }
    } catch (error) {
      const errorMessage = error.response.data.message
      notificationError('Login Gagal', `${errorMessage ? errorMessage : 'Silahkan periksa kembali form anda'}`)
      Object.values(errorMessage).forEach((el) => {
        Object.keys(formValidation).forEach((element) => {
          if (el.includes(element)) {
            setValidationForm((old) => ({
              ...old,
              [element]: {
                ...old?.[element],
                isError: true,
                message: el
              }
            }))
          }
        })
      })
    } finally {
      setLoading(false)
    }
  }

  return (
    <Box>
      <Box style={{ display: 'flex' }}>
        <Box h={height} w={width}>
          <Box py={120}>
            <Box align="center">
              <Grid justify='center' align='center'>
                <Grid.Col span={8}>
                  <Box mb={24}>
                    <Image src={ImpactorLogo} h={120} mb={20} alt='pet-impactor' fit="contain" hiddenFrom='md' />
                    <Text className={classes.titleHeader}>Selamat Datang</Text>
                    <Text fz='sm' c='gray.6'>Silahkan masukkan email dan password untuk login</Text>
                  </Box>
                  <Box align="left">
                    <form onSubmit={(e) => {handleSubmit(e)}}>
                      <Box mb="md">
                        <TextInput
                          name="company"
                          placeholder="Masukkan Kode Perusahaan"
                          label="Kode Perusahaan"
                          error={validationForm.company.isError ? `${validationForm.company.message}` : ''}
                          onChange={handleChange}
                          withAsterisk
                        />
                      </Box>
                      <Box mb="md">
                        <TextInput
                          name="email"
                          placeholder="Masukkan e-mail"
                          label="E-mail"
                          error={validationForm.email.isError ? `${validationForm.email.message}` : ''}
                          onChange={handleChange}
                          withAsterisk
                        />
                      </Box>
                      <Box mb="md">
                        <PasswordInput
                          name="password"
                          placeholder="Masukkan password"
                          label="Password"
                          error={validationForm.password.isError ? `${validationForm.password.message}` : ''}
                          onChange={handleChange}
                          withAsterisk
                        />
                      </Box>
                      <Box style={{ margin: '30px 0' }}>
                        <Button fullWidth loading={loading} type="submit">Login</Button>
                      </Box>
                    </form>
                    <Box align="center" mt={20} mb={10}>
                      <Anchor component="button" fz={13}>Lupa password</Anchor>
                    </Box>
                  </Box>
                </Grid.Col>
              </Grid>
            </Box>
          </Box>
        </Box>
        <Box pos="sticky">
          <Box className={classes.authLayout} style={{ backgroundImage: `url(https://natureconservancy-h.assetsadobe.com/is/image/content/dam/tnc/nature/en/photos/w/o/WOPA160517_D056-resized.jpg?crop=864%2C0%2C1728%2C2304&wid=600&hei=800&scl=2.88)`, minHeight: `${height}px`, minWidth: `${width / 2}px`}}>
            <Center h={height}>
              <Card w={width / 4} h={420} className={classes.cardLogo} shadow="sm" padding="lg" radius="lg" >
                <Box ta='center' my={30}>
                  <Image src={ImpactorLogo} h={180} fit='contain' />
                  <Box mt={40}>
                    <Text color='white' fw='500' fz={14}>Created By:</Text>
                    <Box mt={12}>
                      <Image src={PETLogo} h={40} fit='contain' />
                      <Text color='white' fw='600' fz={14}>"PT Properindo Enviro Tech"</Text>
                    </Box>
                    
                  </Box>
                  
                </Box>
              </Card>
            </Center>
          </Box>
        </Box>
      </Box>
    </Box>
  )
}

export default Login